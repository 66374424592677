import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { Seo } from '../../components/seo';
import { MailerLiteEmbed } from '../../components/mailerlite-embed';

const StickWithWebDevelopment = ({ location }) => {
  const pageTitle = 'Stick With Web Development';
  return (
    <Layout pageType="landing" pageTitle={pageTitle}>
      <Seo index={false} follow={false} pathname={location.pathname} />
      <Article>
        <h1 className="fancy">
          The Trick to Sticking With Web Dev&shy;elop&shy;ment
        </h1>
        <p
          style={{
            fontSize: '3rem',
            lineHeight: '1.2',
            marginBottom: '0.2em',
            textAlign: 'center',
          }}>
          Stop the cycle of quitting when things get&nbsp;tough.
        </p>
        <StaticImage
          src="./images/computer-frustration.jpg"
          alt="Man sitting in an orange booth at a silver table, fingers in his hair with a look of frustration, using his laptop"
        />
        <p
          style={{
            fontSize: '3rem',
            fontWeight: '400',
            lineHeight: '1.2',
            textAlign: 'center',
          }}>
          Professional web developers aren't smarter than you.
          <br />
          <strong>They just made it to the finish line.</strong>
        </p>
        <p>
          A career in web development is enticing. The pay is good, great
          benefits, loads of autonomy, room to be creative, and sometimes the
          opportunity to work remotely. That makes it easy to start learning web
          development, but the difficulty makes it{' '}
          <strong>just as easy to quit</strong>.
        </p>
        <p
          style={{
            fontSize: '3rem',
            fontWeight: '400',
            lineHeight: '1.2',
            marginBottom: '0.2em',
            textAlign: 'center',
          }}>
          You have to find a way to break that cycle.
        </p>
        <p>
          I know because <strong>I was caught in the cycle for years</strong>{' '}
          before I finally found a way to break out. Now, I’m a professional web
          developer. I have a fulfilling job, live where I want, and make my own
          hours. Every day is exciting. My job is challenging, and I’m
          constantly learning… and <strong>it feels amazing</strong>.
        </p>
        <p>
          Here’s the story of how I finally broke through and{' '}
          <strong>how you can do it too</strong>.
        </p>
        <p>
          I worked jobs I was unhappy in for about <strong>15 years</strong>. I
          woke up every morning wondering if I could justify not going in that
          day. Every vacation ended in a crisis, me asking myself, “What am I
          doing? Is this all there is? When’s my next vacation?”
        </p>
        <p>
          The saddest part is that I already knew a lot about web development. I
          had learned quite a bit over the years and even built a WordPress site
          or two on the side. The only thing I was lacking was the{' '}
          <strong>motivation and willpower</strong> to fill in the gaps in my
          knowledge and transition careers.
        </p>
        <p>
          That all changed when I fell in love with Seattle, Washington. It
          became my <strong>Big Goal</strong> to live in Seattle, and that
          pushed me forward into a new career and a new life with{' '}
          <strong>more possibilities than I could have imagined</strong>. I
          reminded myself of my Big Goal all the time. I fantasy-shopped for
          apartments, read everything I could on the city, studied its history,
          watched every YouTube video with “Seattle” in the title, and virtually
          toured the streets using Google Street View. The constant reminders
          kept me focused on doing whatever it took to get there.
        </p>
        <p>
          Since I discovered this hack, I’ve worked with amazing startups, built{' '}
          <a href="https://github.com/raddevon/flask-permissions">
            open-source libraries that people actually use
          </a>
          , written a book, and had{' '}
          <a href="https://www.entrepreneur.com/article/242406">
            an article published on Entrepreneur.com
          </a>
          . That isn’t all though…
        </p>
        <p
          style={{
            fontSize: '3rem',
            fontWeight: '400',
            lineHeight: '1.2',
            marginBottom: '0.2em',
            textAlign: 'center',
          }}>
          After years of hard work, I achieved my Big Goal and made it to
          Seattle.
        </p>
        <StaticImage
          src="./images/moved-to-seattle-achievement.png"
          alt='A fake Xbox achievement notification reading "Achievement unlocked, moved to Seattle"'
          style={{ display: 'block' }}
          imgStyle={{ width: '591px', maxWidth: '100%', margin: 'auto' }}
        />
        <p>
          I’ve analyzed how I was able to transition from the failure cycle to
          winning, and it starts with the Big Goal. If you set a sticky Big Goal
          and remind yourself of it frequently, you have a fighting chance at
          pushing through the friction and frustration that comes when learning
          web development gets difficult.
        </p>
        <p>
          <strong>Willpower is a limited resource.</strong> As you bang your
          head against a difficult task, willpower gets used up. Setting the
          right Big Goal and using it effectively{' '}
          <strong>allows you to refill your willpower reserves</strong> and give
          yourself a nearly unlimited supply of perseverance.
        </p>
        <p>
          This is really all it takes. You don’t have to be a math genius. You
          don’t have to be a business mastermind. You don’t even have to have a
          bachelor’s degree. (I don’t.) All you need is a tool to help you push
          through. <strong>Your Big Goal is that tool.</strong>
        </p>
        <h2>
          Get to <i>your</i> finish line!
        </h2>
        <p>
          This short email course will help you{' '}
          <strong>identify a Big Goal</strong> that will propel you to{' '}
          <strong>a new career in web development</strong> and{' '}
          <strong>a new life</strong>.
        </p>
        <MailerLiteEmbed
          formUrl="https://dashboard.mailerlite.com/forms/90414/60928458118661464/share"
          height="559px"
        />
      </Article>
    </Layout>
  );
};
export default StickWithWebDevelopment;
